import { SearchResponse } from '@algolia/client-search';
import { AlgoliaWaterbodyDetail } from '@omniafishing/core';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import classNames from 'classnames';
import _ from 'lodash';
import React, { ReactElement, useRef, useState } from 'react';
import { searchClient } from '../../algolia';
import { getEnv } from '../../env';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { useUser } from '../../hooks/use_user';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { waterbodyToAlgoliaWaterbodyDetailLossy } from '../../lib/waterbody';
import { MapQueryParams } from '../map_default/map_default';
import { waterbodyStatesCountiesString } from '../search_universal/search_waterbody';
import styles from './omnia_map_search.less';
export interface DebounceSelectProps
  extends Omit<SelectProps<WaterbodySelectValue>, 'options' | 'children'> {
  throttleMs?: number;
}

interface OmniaMapSearchSelectProps extends DebounceSelectProps {
  className?: string;
  geoCoderExpanded?: boolean;
  focusOnMount?: boolean;
}

export interface WaterbodySelectValue {
  label: ReactElement<{ 'data-result': AlgoliaWaterbodyDetail }>;
  value: string;
}

const env = getEnv();
const waterbodiesIndex = searchClient.initIndex(env.ALGOLIA_INDEX_WATERBODIES);

export function OmniaMapSearchSelect({
  throttleMs = 300,
  className,
  geoCoderExpanded,
  focusOnMount,
  ...props
}: OmniaMapSearchSelectProps) {
  const { userPreferencesWaterbodies } = useUserPreferences();
  const { isMobile } = useResponsive();
  const searchInputRef = useRef(null);
  const searchWrapperRef = useRef(null);

  const { currentQuery } = useQueryString();
  const currentWaterbody = currentQuery[MapQueryParams.waterbody_slug] as string;

  const [fetching, setFetching] = useState(false);
  const userPrefsWaterbodiesAsSelectValues = userPreferencesWaterbodies.slice(0, 5).map((w) => ({
    label: (
      <div className={styles.result} data-result={waterbodyToAlgoliaWaterbodyDetailLossy(w)}>
        <div className={styles.resultText}>
          <strong>{w.primary_name} </strong>
          <span>
            {waterbodyStatesCountiesString(
              w.locales.map((l) => l.state.abbr),
              _.flatten(w.locales.map((l) => l.counties))
            )}
          </span>
        </div>
      </div>
    ),
    value: w.url_slug,
  }));
  const [options, setOptions] = React.useState<WaterbodySelectValue[]>(
    userPrefsWaterbodiesAsSelectValues
  );
  const fetchRef = React.useRef(0);
  const { user } = useUser();

  async function fetchLakes(query: string): Promise<WaterbodySelectValue[]> {
    return waterbodiesIndex
      .search(query, {
        clickAnalytics: true,
        getRankingInfo: true,
        enablePersonalization: true,
        userToken: user?.id?.toString(),
      })
      .then((results: SearchResponse<AlgoliaWaterbodyDetail>) => {
        return results.hits.map((result) => {
          const { primary_name, url_slug, locales } = result;
          const states = locales.map((l) => l.state.abbr);
          const counties = _.flatten(locales.map((l) => l.counties));

          return {
            label: (
              <div className={styles.result} data-result={result}>
                <div className={styles.resultText}>
                  <strong>{primary_name} </strong>
                  <span>{waterbodyStatesCountiesString(states, counties)}</span>
                </div>
              </div>
            ),
            value: url_slug,
          };
        });
      });
  }

  const throttledFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      if (value.length === 0) {
        setFetching(false);
        setOptions(userPrefsWaterbodiesAsSelectValues);
      } else {
        fetchLakes(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          setOptions(newOptions);
          setFetching(false);
        });
      }
    };

    return _.throttle(loadOptions, throttleMs);
  }, [fetchLakes, throttleMs]);

  return (
    <div className={classNames(styles.searchSelectWrapper, className)} ref={searchWrapperRef}>
      <div
        className={classNames(styles.searchSelect, {
          [styles.searchSelect__open]: true,
        })}
        onClick={() => {
          if (isMobile && searchInputRef) {
            setTimeout(() => {
              searchInputRef.current.focus();
            }, 0);
          }
        }}
      >
        <Select<WaterbodySelectValue>
          ref={searchInputRef}
          labelInValue
          filterOption={false}
          onSearch={throttledFetcher}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          {...props}
          options={options}
          autoFocus={!currentWaterbody && focusOnMount}
          defaultOpen={userPreferencesWaterbodies.length && !currentWaterbody && focusOnMount}
        />
      </div>
    </div>
  );
}
